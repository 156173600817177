import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery
} from '../resources/appSectionResource'

export default class AppSectionRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async index(data) {
        const response = await axios.get(url('indexDashboardData'))
        if (response.status === 200) {
            return response.data
        }
    }
}
