<template>
    <div>
        <section id="dashboard-analytics" :class="{ 'display-none': isPhoneScreen }">
            <b-row>
                <b-col cols="3" lg="3" md="3" sm="12" xs="12">
                    <!-- <b-card> -->
                    <div class="userCard" :style="{ 'background-image': `url(${require('@/views/dashboard/images/USERS.png')})` }">
                        <b-row class="justify-content-between">
                            <b-img :src="require('@/views/dashboard/images/userIcon.png')" class="mt-1 ml-3" />

                            <span class="font-weight-bold card-title-size">USERS</span>
                        </b-row>
                        <Transition name="slide-fade">
                            <span v-if="dashboardData" class="counter-position" :key="dashboardData.totals.users_count">{{ dashboardData.totals.users_count }}</span>
                        </Transition>
                    </div>
                    <div class="userCard1" :style="{ 'background-image': `url(${require('@/views/dashboard/images/STORY.png')})` }">
                        <b-row class="justify-content-between">
                            <b-img :src="require('@/views/dashboard/images/storyIcon.png')" class="mt-1 ml-3" />
                            <span class="font-weight-bold card-title-size">STORIES</span>
                        </b-row>
                        <Transition name="slide-fade">
                            <span v-if="dashboardData" class="counter-position" :key="dashboardData.totals.stories_count">{{ dashboardData.totals.stories_count }}</span>
                        </Transition>
                    </div>
                    <div class="userCard2" :style="{ 'background-image': `url(${require('@/views/dashboard/images/POSTS.png')})` }">
                        <b-row class="justify-content-between">
                            <b-img :src="require('@/views/dashboard/images/postIcon.png')" class="mt-1 ml-3" />
                            <span class="font-weight-bold card-title-size">POSTS</span>
                        </b-row>
                        <Transition name="slide-fade">
                            <span v-if="dashboardData" class="counter-position" :key="dashboardData.totals.posts_count">{{ dashboardData.totals.posts_count }}</span>
                        </Transition>
                    </div>
                    <div class="userCard3" :style="{ 'background-image': `url(${require('@/views/dashboard/images/MUSIC.png')})` }">
                        <b-row class="justify-content-between">
                            <b-img :src="require('@/views/dashboard/images/musicIcon.png')" class="mt-1 ml-3" />
                            <span class="font-weight-bold card-title-size">MUSIC</span>
                        </b-row>
                        <Transition name="slide-fade">
                            <span v-if="dashboardData" class="counter-position" :key="dashboardData.totals.music_count">{{ dashboardData.totals.music_count }}</span>
                        </Transition>
                    </div>
                    <div class="userCard4" :style="{ 'background-image': `url(${require('@/views/dashboard/images/VIDEO.png')})` }">
                        <b-row class="justify-content-between">
                            <b-img :src="require('@/views/dashboard/images/videoIcon.png')" class="mt-1 ml-3" />
                            <span class="font-weight-bold card-title-size">MUSIC VIDEO</span>
                        </b-row>
                        <Transition name="slide-fade">
                            <span v-if="dashboardData" class="counter-position" :key="dashboardData.totals.music_videos_count">{{ dashboardData.totals.music_videos_count }}</span>
                        </Transition>
                    </div>
                    <!-- </b-card> -->
                </b-col>
                <b-col cols="9" lg="9" md="9" sm="12" xs="12">
                    <b-row>
                        <b-col cols="9" lg="9" md="9" sm="12" xs="12" :class="{ 'phone-only2': isPhoneScreen }">
                            <!-- <apexchart type="bar" height="320" :options="chartOptions" :users="users"></apexchart> -->
                            <b-row class="justify-content-center">
                                <h2 class="font-weight-bolder mr-50">Registered users</h2>
                                <h6 class="mt-50">(Last 30 Days)xx</h6>
                            </b-row>
                            <apexchart type="bar" height="250" :options="chartOptions" :series="users"></apexchart>
                        </b-col>
                        <b-col class="text-center" cols="3" lg="3" md="3" sm="12" xs="12">
                            <!-- <b-row class="justify-content-center"> -->
                            <b-img :src="require('@/views/dashboard/images/totalUserIcon.png')" class="mt-2 ml-3" />
                            <!-- </b-row> -->
                            <!-- <b-row class="justify-content-center"> -->
                            <!-- <Transition name="slide-fade"> -->
                            <span class="total-user-counter ml-3" :key="dashboardData.totals.users_count">
                                {{ dashboardData.totals.users_count }}
                            </span>
                            <!-- </Transition> -->
                            <!-- </b-row> -->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4" lg="4" md="4" class="donutChartCardContainer" sm="12" xs="12">
                            <div class="donutChartCard" :class="{ 'phone-only1': isPhoneScreen }">
                                <h3 class="mt-1">CONTENTS ON NOJOUM</h3>
                                <apexchart class="my-1 donutChart" type="pie" width="520" :options="chartOptions2" :series="series2"></apexchart>
                            </div>
                        </b-col>
                        <b-col cols="8" lg="8" md="8" sm="12" xs="12">
                            <b-row class="text-nowrap justify-content-end">
                                <b-col cols="4" lg="4" md="4" sm="12" xs="12" class="info-card">
                                    <b-col cols="12" sm="12" xs="12">
                                        <b-row class="justify-content-center m-50">
                                            <!-- <feather-icon icon="UsersIcon" size="35" /> -->
                                            <b-img :src="require('@/views/dashboard/images/users-icon3.png')" class="titleIcon" />
                                        </b-row>
                                        <b-row class="justify-content-center">
                                            <h3 class="font-weight-bolder text-light text-center mt-25">Top 3 followed users</h3>
                                        </b-row>
                                        <b-row class="text-nowrap justify-content-between data-sections">
                                            <b-col cols="7" md="7" lg="7">
                                                <b-row class="justify-content-start">
                                                    <b-avatar class="mt-1 thumbnail-shadow" size="65" :src="dashboardData.most_followed_users[0].thumbnail" />
                                                    <b-img :src="require('@/views/dashboard/images/gold-medal.png')" class="medal" />
                                                </b-row>

                                                <b-row class="justify-content-start">
                                                    <span class="username-font" :class="(dashboardData.most_followed_users[0].user_name.length > 8 ? 'username-font-smaller' : 'username-font', dashboardData.most_followed_users[0].user_name.length < 5 ? 'ml-2' : '')">{{ dashboardData.most_followed_users[0].user_name }}</span>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="5" md="5" lg="5">
                                                <b-row class="justify-content-center">
                                                    <h1 class="mt-2 font-weight-bolder dataCounter">{{ dashboardData.most_followed_users[0].followers_count }}</h1>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row class="text-nowrap justify-content-between data-sections mt-1">
                                            <b-col cols="8" md="8" lg="8">
                                                <b-row class="justify-content-start">
                                                    <b-avatar class="mt-1 thumbnail-shadow" size="65" :src="dashboardData.most_followed_users[1].thumbnail" />
                                                    <b-img :src="require('@/views/dashboard/images/silver-medal.png')" class="medal" />
                                                </b-row>

                                                <b-row class="justify-content-start">
                                                    <span class="username-font text-center" :class="(dashboardData.most_followed_users[1].user_name.length > 8 ? 'username-font-smaller' : 'username-font', dashboardData.most_followed_users[1].user_name.length < 5 ? 'ml-2' : '')">{{ dashboardData.most_followed_users[1].user_name }}</span>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="4" md="4" lg="4">
                                                <b-row class="justify-content-center">
                                                    <h1 class="mt-2 font-weight-bolder dataCounter">{{ dashboardData.most_followed_users[1].followers_count }}</h1>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row class="text-nowrap justify-content-between data-sections mt-1">
                                            <b-col cols="8" md="8" lg="8">
                                                <b-row class="justify-content-start">
                                                    <b-avatar class="mt-1 thumbnail-shadow" size="65" :src="dashboardData.most_followed_users[2].thumbnail" />
                                                    <b-img :src="require('@/views/dashboard/images/bronze-medal.png')" class="medal" />
                                                </b-row>
                                                <b-row class="justify-content-start">
                                                    <span class="username-font" :class="(dashboardData.most_followed_users[2].user_name.length > 8 ? 'username-font-smaller' : 'username-font', dashboardData.most_followed_users[2].user_name.length < 5 ? 'ml-2' : '')">{{ dashboardData.most_followed_users[2].user_name }}</span>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="4" md="4" lg="4" class="text-center">
                                                <b-row class="justify-content-center">
                                                    <h1 class="mt-2 font-weight-bolder dataCounter">{{ dashboardData.most_followed_users[2].followers_count }}</h1>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-col>
                                <b-col cols="4" lg="4" md="4" sm="12" xs="12" class="info-card4">
                                    <b-col cols="12" sm="12" xs="12">
                                        <b-row class="justify-content-center m-50">
                                            <!-- <feather-icon icon="TwitchIcon" size="35" /> -->
                                            <b-img :src="require('@/views/dashboard/images/posts1.png')" class="titleIcon" />
                                        </b-row>
                                        <b-row class="justify-content-center">
                                            <h4 class="font-weight-bolder text-light mt-50 text-center">Users with the most posts</h4>
                                        </b-row>
                                        <b-row class="text-nowrap justify-content-between data-sections">
                                            <b-col cols="8" md="8" lg="8">
                                                <b-row class="justify-content-start">
                                                    <b-avatar class="mt-1 thumbnail-shadow" size="65" :src="dashboardData.users_with_most_posts[0].thumbnail" />
                                                    <b-img :src="require('@/views/dashboard/images/gold-medal.png')" class="medal" />
                                                </b-row>
                                                <b-row class="justify-content-start">
                                                    <span class="username-font" :class="(dashboardData.users_with_most_posts[0].user_name.length > 8 ? 'username-font-smaller' : 'username-font', dashboardData.users_with_most_posts[0].user_name.length < 5 ? 'ml-2' : '')">{{ dashboardData.users_with_most_posts[0].user_name }}</span>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="4" md="4" lg="4">
                                                <b-row class="justify-content-center">
                                                    <h1 class="mt-2 font-weight-bolder dataCounter">{{ dashboardData.users_with_most_posts[0].posts_count }}</h1>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row class="text-nowrap justify-content-between data-sections mt-1">
                                            <b-col cols="8" md="8" lg="8">
                                                <b-row class="justify-content-start">
                                                    <b-avatar class="mt-1 thumbnail-shadow" size="65" :src="dashboardData.users_with_most_posts[1].thumbnail" />
                                                    <b-img :src="require('@/views/dashboard/images/silver-medal.png')" class="medal" />
                                                </b-row>
                                                <b-row class="justify-content-start">
                                                    <span class="username-font" :class="(dashboardData.users_with_most_posts[1].user_name.length > 8 ? 'username-font-smaller' : 'username-font', dashboardData.users_with_most_posts[1].user_name.length < 5 ? 'ml-2' : '')">{{ dashboardData.users_with_most_posts[1].user_name }}</span>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="4" md="4" lg="4">
                                                <b-row class="justify-content-center">
                                                    <h1 class="mt-2 font-weight-bolder dataCounter">{{ dashboardData.users_with_most_posts[1].posts_count }}</h1>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row class="text-nowrap justify-content-between data-sections mt-1">
                                            <b-col cols="8" md="8" lg="8">
                                                <b-row class="justify-content-start">
                                                    <b-avatar class="mt-1 thumbnail-shadow" size="65" :src="dashboardData.users_with_most_posts[2].thumbnail" />
                                                    <b-img :src="require('@/views/dashboard/images/bronze-medal.png')" class="medal" />
                                                </b-row>
                                                <b-row class="justify-content-start">
                                                    <span class="username-font" :class="(dashboardData.users_with_most_posts[2].user_name.length > 8 ? 'username-font-smaller' : 'username-font', dashboardData.users_with_most_posts[2].user_name.length < 5 ? 'ml-2' : '')">{{ dashboardData.users_with_most_posts[2].user_name }}</span>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="4" md="4" lg="4">
                                                <b-row class="justify-content-center">
                                                    <h1 class="mt-2 font-weight-bolder dataCounter">{{ dashboardData.users_with_most_posts[2].posts_count }}</h1>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-col>
                                <b-col cols="3" lg="3" md="3" sm="12" xs="12" class="info-card3" :class="{ 'phone-only': isPhoneScreen }">
                                    <div class="cols-10 text-center mt-4">
                                        <!-- <feather-icon icon="UserCheckIcon" size="35" class="UserCheckIcon" /> -->
                                        <b-img :src="require('@/views/dashboard/images/live-chat.png')" class="titleIcon" />
                                        <h4 class="font-weight-bolder text-light mt-1">Users on livechat</h4>
                                        <span class="data-info-number">{{ likeCount }}</span>
                                        <!-- <h4 class="mt-1">Increased by 41%</h4> -->
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </section>
        <mobileLayout :class="{ 'display-none': !isPhoneScreen }" />
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCol, BRow, BLink, BCard, BMedia, BImg, BCardText, BAvatar, BButton } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import dashboardRepository from "@/abstraction/repository/dashboardRepository";
import Pusher from "pusher-js";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import liveRepository from "@/abstraction/repository/liveChatRepository.js";
import mobileLayout from "./mobile-layout.vue";
const dashboard = new dashboardRepository();
const repository = new liveRepository();
export default {
    components: {
        mobileLayout,
        BAvatar,
        BButton,
        BCardText,
        BRow,
        BMedia,
        BImg,
        BLink,
        BCol,
        BCard,
        StatisticCardHorizontal,
        StatisticCardWithAreaChart,
        apexchart: VueApexCharts,
    },
    setup() {
        const dashboardData = ref();
        const totalData = ref([]);
        const connection = ref(null);
        const pusherData1 = ref();
        const likeCount = ref();
        const isPhoneScreen = ref(false);

        const updateScreenSize = () => {
            isPhoneScreen.value = window.matchMedia("(max-width: 580px)").matches;
        };
        const chatId = ref();
        const showActiveLiveHandler = async () => {
            await repository.showActiveLive().then((res) => {
                chatId.value = res.data.data.id;
            });
        };
        onMounted(async () => {
            updateScreenSize(); // Check initial screen size

            // Listen for screen size changes
            window.addEventListener("resize", updateScreenSize);
            await showActiveLiveHandler();
            dashboard.index().then((res) => {
                dashboardData.value = res;
                chartOptions.value.xaxis.categories = res.last_30_days.users.map((item) => {
                    return item.date;
                });
                users.value[0].data = res.last_30_days.users.map((item) => {
                    return item.count;
                });
                series2.value = Object.values(res.totals).map(Number);
                series2.value.shift();
                console.log(dashboardData.value.users_with_most_posts[3].user_name.length);
            });
            //pusher
            const channelName = ref();
            if (window.location.hostname === "dev.nojoum.app" || window.location.hostname === "localhost") {
                channelName.value = "staging_";
            } else if (window.location.hostname === "app.nojoum.app") {
                channelName.value = "";
            }
            Pusher.logToConsole = true;
            const pusher = new Pusher("c709e9abca9c982a8865", {
                cluster: "mt1",
            });
            const channel = pusher.subscribe(`${channelName.value}nojoum_panel_report_dashboard_data`);
            channel.bind("panel_report_dashboard_data_part_1", async (data) => {
                // pusherData1.value = data;
                // dashboardData.value = data;
                dataAnalyse(data);
            });
            const appChannel = pusher.subscribe(`${channelName.value}live_chat.${chatId.value}`);
            appChannel.bind("live-subscription-count", (data) => {
                likeCount.value = data.data.subscription_count;
            });
        });

        const users = ref([
            {
                data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
            },
        ]);

        const chartOptions = ref({
            chart: {
                type: "bar",
                height: 200,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    // horizontal: true,
                },
            },
            fill: {
                colors: "#f32900",
                gradient: {
                    shade: "dark",
                    type: "horizontal",
                    shadeIntensity: 0.5,
                    gradientToColors: "#41A28C",
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 100],
                    colorStops: [],
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: "middle",
                enabledOnSeries: true,
                distributed: true,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize: "20px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                    // colors: "#fff",
                },
            },
            tooltip: {
                enabled: false,
                fillSeriesColor: "#ccc",
                onDatasetHover: {
                    highlightDataSeries: true,
                },
                y: {
                    formatter: "USERS",
                    title: "Users",
                },
                x: {
                    show: true,
                    format: "dd MMM",
                    formatter: "USERS",
                    title: "Users",
                },
                z: {
                    formatter: undefined,
                    title: "Size: ",
                },
            },
            xaxis: {
                categories: ["South Korea", "Canada", "United Kingdom", "Netherlands", "Italy", "France", "Japan", "United States", "China", "Germany"],
                labels: {
                    show: true,
                    style: {
                        colors: "#ccc",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: "#ccc",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                        cssClass: "apexcharts-yaxis-label",
                    },
                },
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            title: {
                text: "",
                align: "left",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: undefined,
                    color: "#5b24ef",
                },
            },
        });
        const series2 = ref([44, 55, 13, 43, 22]);
        const chartOptions2 = ref({
            chart: {
                width: 400,
                type: "pie",
            },
            stroke: {
                width: 0,
            },
            legend: {
                show: true,
                fontSize: "16px",
                labels: {
                    colors: "#ccc",
                    useSeriesColors: false,
                },
                position: "bottom",
            },
            dataLabels: {
                style: {
                    fontSize: "16px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                    color: "#fff",
                },
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "horizontal",
                    shadeIntensity: 0.5,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 100],
                    colorStops: [],
                },
            },
            labels: ["Post", "Stories", "Musics", "Music Videos"],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        });
        const dataAnalyse = (data) => {
            console.log("HIII", data);
            console.log(dashboardData.value);
            dashboardData.value.totals = data.totals;
            dashboardData.value.most_followed_users = data.most_followed_users;
            dashboardData.value.last_30_days = data.last_30_days;
        };
        const add = () => {
            dashboardData.value.totals.users_count++;
            dashboardData.value.totals.music_count++;
            dashboardData.value.totals.stories_count++;
        };

        return {
            add,
            dashboardData,
            totalData,
            users,
            chartOptions,
            series2,
            chartOptions2,
            likeCount,
            isPhoneScreen,
        };
    },
};
</script>
<style>
.display-none {
    display: none;
}
.phone-only {
    height: 350px;
}
.thumbnail-shadow {
    box-shadow: 5px 5px 15px rgb(36, 36, 36);
}
.data-sections {
    margin-top: -10px;
}
.titleIcon {
    width: 55px;
    height: 55px;
}
.medal-second-section {
    width: 25px;
    height: 25px;
    margin-left: -30px;
    margin-top: 20px;
}
.medal-second-section-third {
    margin-left: -36px;
}
.dataCounter {
    font-size: 40px;
    margin-left: -55px;
    text-shadow: 5px 5px 8px rgb(38, 0, 57);
    color: #ffffff;
}
.medal {
    width: 35px;
    height: 35px;
    margin-left: -20px;
    margin-top: 18px;
    z-index: 100;
}
.third-medal {
    margin-left: 0px;
}
.UserCheckIcon {
    margin-top: -20px;
}
.username-font {
    font-size: 20px;
    color: white;
    text-shadow: 2px 2px 8px rgb(38, 0, 57);
}
.username-font-smaller {
    font-size: 15px;
    color: white;
    text-shadow: 2px 2px 8px rgb(38, 0, 57);
}
.userCard {
    position: relative;
    max-width: 387px;
    min-width: 250px;
    height: 130px;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-position: -40px -57px;
    margin-top: 15px;
}
.userCard1 {
    position: relative;
    max-width: 387px;
    min-width: 250px;
    height: 130px;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-position: 0px 0px;
    margin-top: 15px;
}
.userCard2 {
    position: relative;
    max-width: 387px;
    min-width: 250px;
    height: 130px;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-position: 0px -50px;
    margin-top: 15px;
}
.userCard3 {
    position: relative;
    max-width: 387px;
    min-width: 250px;
    height: 130px;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-position: -25px 0px;
    margin-top: 15px;
}
.userCard4 {
    position: relative;
    max-width: 387px;
    min-width: 250px;
    height: 130px;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-position: 0px 10px;
    margin-top: 15px;
}
.card-title-size {
    font-size: 30px;
    font-weight: 700;
    color: rgb(216, 216, 216);
    text-shadow: 3px 3px 5px rgb(0, 0, 0);
}
.counter-position {
    position: absolute;
    left: 120px;
    top: 55px;
    font-size: 80px;
    font-weight: 900;
    color: aliceblue;
    text-shadow: 8px 8px 5px rgb(0, 0, 0);
}
.total-user-counter {
    font-size: 65px;
    font-weight: 800;
    color: #0fea5c;
    text-shadow: 8px 8px 5px rgb(0, 0, 0);
    /* margin-right: 50px; */
    margin-top: 0px;
}
.donutChartCardContainer {
    margin-top: -20px;
}
.donutChartCard {
    background-color: #2b2b2b0c;
    /* background-color: #010003e4; */
    display: flex;
    flex-direction: column;
    width: 400px;
    box-shadow: 0px 0px 15px rgba(104, 104, 104, 0.096);
    border-radius: 5px;
}
.datasCardContainer {
    box-shadow: 0px 0px 15px rgba(104, 104, 104, 0.22);
    border-radius: 5px;
}
.donutChart {
    margin-left: -60px;
}
.info-card {
    background: rgb(178, 36, 239);
    background: linear-gradient(180deg, rgba(178, 36, 239, 1) 23%, rgba(122, 114, 254, 1) 100%);
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
    max-height: 500px;
}
.info-card1 {
    background: #24efc8;
    background: linear-gradient(180deg, #24efc8 23%, #0a597b 100%);
    width: 200px;
    height: 263px;
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
}
.info-card2 {
    background: #efdf24;
    background: linear-gradient(180deg, #efdf24 23%, #d04432 100%);
    width: 193px;
    height: 263px;
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
    margin-left: -20px;
}
.info-card3 {
    background: #5b24ef;
    background: linear-gradient(180deg, #5b24ef 23%, #63e6b7 100%);
    /* background: rgb(0, 0, 0);
    background: linear-gradient(3deg, rgba(0, 0, 0, 1) 26%, rgba(255, 0, 0, 1) 100%); */
    /* max-width: 200px; */
    /* height: 330px; */
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
    /* margin-left: 20px; */
    /* margin-top: 5px; */
}
.info-card4 {
    background: #ef2424;
    background: linear-gradient(180deg, #5b24ef 23%, #771c7d 100%);
    /* background: rgb(0, 0, 0);
    background: linear-gradient(3deg, rgba(0, 0, 0, 1) 26%, rgba(255, 0, 0, 1) 100%); */
    /* width: 293px; */
    /* max-width: 215px; */
    /* height: 330px; */
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
    /* margin-top: 5px; */
}
.info-card5 {
    background: #ef6a24;
    background: linear-gradient(180deg, #ef6a24 23%, #eeb264 100%);
    width: 193px;
    height: 263px;
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
    margin-left: -20px;
    /* margin-top: 5px; */
}
.data-info-number {
    font-size: 65px;
    font-weight: 700;
    color: #ffffff;
    position: relative;
    top: 30px;
    text-shadow: 8px 8px 5px rgb(0, 158, 132);
}
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
.counter-enter-active,
.counter-leave-active {
    transition: transform 0.5s;
}

.counter-enter {
    transform: translateY(100%);
    opacity: 0;
}

.counter-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
.flip-enter-active,
.flip-leave-active {
    transition: transform 0.5s;
}

.flip-enter {
    transform: rotateY(-180deg);
    opacity: 0;
}

.flip-leave-to {
    transform: rotateY(180deg);
    opacity: 0;
}
</style>
